<template>
  <transition name="popup-fade">
    <div
      class="popup-body"
    >
      <div class="popup-content-wrap">
        <div class="popup">
          <div class="title-box">
            <i class="el-icon-close" @click="doClose" />
          </div>
          <div class="traffic-body-box">
            <el-form ref="trafficItem" :model="trafficItem" :rules="rules" class="traffic-form">
              <div class="traffic-div">
                <div class="traffic-label">{{ transportMeans }}</div>
                <el-form-item prop="displayname">
                  <el-autocomplete
                    v-model="trafficItem.displayname"
                    size="small"
                    class="traffic-input"
                    :fetch-suggestions="querySearch"
                    :placeholder="$t('messages.tranCheck')"
                    :trigger-on-focus="true"
                    @select="((item) => {handleSelect(item)})"
                    @change="inputChange(trafficItem.displayname)"
                  >
                    <template v-slot="{ item }">
                      <span>{{ item.displayname }}</span>
                    </template>
                  </el-autocomplete>
                </el-form-item>
              </div>
              <div class="traffic-div1">
                <div class="traffic-label">{{ transportDistance }}</div>
                <el-form-item prop="trafficDistance">
                  <el-input v-model="trafficItem.trafficDistance" class="traffic-input" size="small" title="" :placeholder="$t('messages.tranSpotCheck')" @input="value => trafficItem.trafficDistance=value.replace(/\D*(\d*)(\.?)(\d*)\d*/,'$1$2$3').match(/^\d*(\.?\d*)/g)[0] || ''" />
                  <span class="traffic-label" style="margin-left: 1rem">km</span>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="tab-button-box">
            <el-button class="back-button" @click="doClose">{{ backName }}</el-button>
            <el-button
              class="calculator-button"
              @click="doComfirm('trafficItem')"
            >
              {{ buttonName }}
              <i class="el-icon-check" />
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/ecmascript-6">
import { times, divide } from '../../../utils/number'
export default {
  name: 'TrafficPopup',
  props: {
    isShowTrafficPopup: Boolean,
    isComfirm: Boolean,
    tkmNameList: {
      type: Array,
      default: () => {}
    },
    nameArray: {
      type: Array,
      default: () => {}
    },
    trafficItemWeight: {
      type: Number,
      default: () => {}
    },
    buttonName: {
      type: String,
      default: ''
    },
    backName: {
      type: String,
      default: '戻る'
    },
    language: {
      type: String,
      default: ''
    },
    transportMeans: {
      type: String,
      default: '輸送手段'
    },
    transportDistance: {
      type: String,
      default: '輸送距離'
    },
    trafficUnitList: {
      type: Array,
      default: () => {}
    },
    trafficUnit: {
      type: String,
      default: ''
    }
  },
  data() {
    var checkgentani = (rule, value, callback) => {
      if (!this.nameArray.includes(value) && value != '') {
        return callback(new Error(this.$t('messages.simulateCheckRightIdea')))
      }
      return callback()
    }
    return {
      trafficItem: {
        name: '',
        trafficDistance: '',
        displayname: ''
      },
      ideacode: '',
      coefficient: '',
      itemVersion: '',
      unit: '',
      itemQuantity: 0,
      rules: {
        displayname: [
          { required: true, message: '輸送手段を入力してください', trigger: 'blur' },
          { validator: checkgentani, trigger: ['blur', 'change'] }
        ],
        trafficDistance: [
          { required: true, message: '輸送距離を入力してください', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    // '$i18n.locale'() {
    //   this.rules= {
    //     name: [
    //       { required: true, message: this.$t('messages.tranCheck'), trigger: 'blur' },
    //       { validator: this.checkgentani, trigger: ['blur', 'change'] }
    //     ],
    //     trafficDistance: [
    //       { required: true, message: this.$t('messages.tranSpotCheck'), trigger: 'blur' }
    //     ]
    //   }
    // }
    language: {
      immediate: true,
      handler(newVal, oldVal) {
        this.rules = {
          displayname: [
            { required: true, message: this.$t('messages.tranCheck'), trigger: 'blur' },
            { validator: this.checkgentani1, trigger: ['blur', 'change'] }
          ],
          trafficDistance: [
            { required: true, message: this.$t('messages.tranSpotCheck'), trigger: 'blur' }
          ]
        }
      },
      deep: true
    }
  },
  methods: {
    doComfirm(trafficForm) {
      this.$refs[trafficForm].validate((valid) => {
        if (!valid) {
          return false
        }
      })
      if (!(this.trafficItem.name && this.trafficItem.trafficDistance)) {
        return
      }
      if (!this.nameArray.includes(this.trafficItem.displayname)) {
        return
      }
      if (this.trafficUnitList.some(item => { return item.tounit === 'kg' })) {
        if (this.trafficUnitList[0].tounit !== 'kg' && this.trafficUnit === this.trafficUnitList[0].tounit) {
          const tempPercent1 = this.trafficUnitList.filter(item => item.tounit === 'kg')
          this.itemQuantity = this.transferToNumber(divide(times(divide(times(this.trafficItemWeight, 1), tempPercent1[0].percent), this.trafficItem.trafficDistance), 1000)) // LPB00003 2025.03.18 Ubicom ビリー 変更
        } else if (this.trafficUnitList[0].tounit !== 'kg' && this.trafficUnit !== 'kg' && this.trafficUnit !== this.trafficUnitList[0].tounit) {
          const tempPercent2 = this.trafficUnitList.filter(item => item.tounit === 'kg')
          const tempPercent3 = this.trafficUnitList.filter(item => item.tounit === this.trafficUnit)
          this.itemQuantity = this.transferToNumber(divide(times(divide(times(this.trafficItemWeight, tempPercent3[0].percent), tempPercent2[0].percent), this.trafficItem.trafficDistance), 1000))
        } else if (this.trafficUnitList[0].tounit === 'kg' && this.trafficUnit !== this.trafficUnitList[0].tounit) {
          const tempPercent4 = this.trafficUnitList.filter(item => item.tounit === this.trafficUnit)
          this.itemQuantity = this.transferToNumber(divide(times(this.trafficItem.trafficDistance, tempPercent4[0].percent, this.trafficItemWeight), 1000))
        } else {
          this.itemQuantity = this.transferToNumber(divide(times(this.trafficItem.trafficDistance, this.trafficItemWeight), 1000))
        }
      }
      this.$emit('childFn', {
        'isComfirm': true,
        'isShowTrafficPopup': false,
        'itemName': this.trafficItem.name,
        'itemCoefficient': this.coefficient,
        'itemIdeacode': this.ideacode,
        'itemQuantity': this.itemQuantity,
        'itemUnit': this.unit,
        'itemVersion': this.itemVersion
      })
      this.trafficItem.name = ''
      this.trafficItem.trafficDistance = ''
      this.trafficItem.displayname = ''
      this.$refs['trafficItem'].resetFields()
    },
    doClose() {
      this.$emit('childFn', { 'isComfirm': false, 'isShowTrafficPopup': false })
      this.trafficItem.name = ''
      this.trafficItem.trafficDistance = ''
      this.trafficItem.displayname = ''
      this.$refs['trafficItem'].resetFields()
    },
    handleSelect(item) {
      this.trafficItem.name = item.name
      this.trafficItem.displayname = item.displayname
      this.ideacode = item.ideacode
      this.coefficient = item.coefficient
      this.unit = item.unit
      this.itemVersion = item.version
    },
    inputChange(name) {
      const array = this.tkmNameList.filter(item => {
        return item.name === name
      })
      if (array.length > 0) {
        this.trafficItem.name = array[0].name
        this.trafficItem.displayname = array[0].displayname
        this.ideacode = array[0].ideacode
        this.unit = array[0].unit
        this.coefficient = array[0].coefficient
        this.itemVersion = array[0].version
      }
    },
    querySearch(queryString, cb) {
      const tkmNameList = this.tkmNameList
      const result = queryString ? tkmNameList.filter(this.createFilter(queryString)) : tkmNameList
      cb(result)
    },
    createFilter(queryString) {
      return (item) => {
        if (item.displayname.indexOf(queryString) > -1) {
          return true
        }
      }
    },
    checkgentani1(rule, value, callback) {
      if (!this.nameArray.includes(value)) {
        return callback(new Error(this.$t('messages.simulateCheckRightIdea')))
      }
      return callback()
    },
    transferToNumber(inputNumber) {
      if (isNaN(inputNumber)) {
        return inputNumber
      }
      if (!inputNumber) {
        return inputNumber
      }
      inputNumber = '' + inputNumber
      inputNumber = parseFloat(inputNumber)
      let eformat = inputNumber.toExponential() // 转换为标准的科学计数法形式（字符串）
      let tmpArray = eformat.match(/\d(?:\.(\d*))?e([+-]\d+)/) // 分离出小数值和指数值
      let number = inputNumber.toFixed(Math.max(0, (tmpArray[1] || '').length - tmpArray[2]))
      return number
    },
  }
}
</script>

<style lang="scss" scoped>
.popup-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .popup-content-wrap {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
    height: 50%;
    width: 30%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    z-index: 999;
    user-select: none;

    .popup {

      .title-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 3%;
        margin-bottom: 1%;
        margin-left: 3%;
        height: 10%;

        .el-icon-close {
          cursor: pointer;
        }

        .title-font {
          font-family: 'NotoSansCJKjp-Medium';
          font-size: 20px;
          color: #333333;
          letter-spacing: 0;
          text-align: center;
          font-weight: 500;
          margin-left: 3%;
        }
      }

      .traffic-body-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 60%;

        .traffic-form {
          height: 100%;
          width: 100%;
          background-color: #FFFFFF;

          .traffic-label {
            font-family: NotoSansCJKjp-Medium;
            font-size: 14px;
            color: #666666;
            letter-spacing: 0;
            font-weight: 500;
            margin-bottom: 0.4rem;
          }

          .traffic-div {
            margin-left: 5%;
            margin-bottom: 10%;
            width: 100%;

            .traffic-input {
              width: 85%;
            }
          }

          .traffic-div1 {
            margin-left: 5%;
            margin-bottom: 10%;
            width: 90%;

            .traffic-input {
              width: 85%;
            }
          }
        }

      }

      .tab-button-box{
        margin-top: 5%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // opacity: 0.85;
        background: #FFFFFF;
        height: 20%;

        .back-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 12px;
          // color: #333333;
          letter-spacing: 0;
          font-weight: 400;
          // border: 1px solid #333333;
          border-radius: 4px;
        }

        .calculator-button {
          font-family: 'NotoSansCJKjp-Regular';
          font-size: 14px;
          color: #FFFFFF;
          letter-spacing: 0;
          font-weight: 400;
          background: #333333;
          border-radius: 4px;
        }
      }
    }
  }
}

/* 进入和出去的动画 */
.popup-fade-enter-active {
  animation: opacity 0.3s;
}
.popup-fade-enter-active .popup-content-wrap {
  animation: scale 0.3s;
}
.popup-fade-leave-active {
  animation: outOpacity 0.2s;
}

/*滚动条样式*/
.process-list::-webkit-scrollbar {
  width: 10px;
  /*height: 4px;*/
}
.process-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);

  background: #CCCCCC;
  border-radius: 4px;
}
.process-list::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);

}

/* 进来的动画 */
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes outOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
